import * as React from 'react'
import EducationNavigation from "./educationNavigation";
import Image from "../global/image";
import logo from "../../images/hallway-heroes-logo.png"

const HallwayHeroesNavigation = (props) => {
    return (
        <>
            <EducationNavigation active={props.active} />
            <Image src={logo} alt="Hallway Heroes Logo" className="sidebar-logo" />
        </>
    )
}

export default HallwayHeroesNavigation