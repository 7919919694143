import React, {useState} from "react"
import {useStaticQuery, graphql} from "gatsby";
import ClientPagination from "../../components/global/clientPagination";
import HallwayHeroesNavigation from "../../components/education/hallwayHeroesNavigation";
import InnerLayout from "../../components/global/innerLayout"
import PageTitle from "../../components/global/pageTitle";
import parse from "html-react-parser";
import Select from "../../components/global/select";
import "./educators-teacher-guides.scss"

const EducatorsTeacherGuidesPage = () => {

    const data = useStaticQuery(graphql`
    {
      craft {
        page: entry(section: "hallwayHeroesTeacherGuides") {
          ... on Craft_hallwayHeroesTeacherGuides_hallwayHeroesTeacherGuides_Entry {
            seomatic {
              metaTitleContainer
              metaTagContainer
              metaLinkContainer
              metaScriptContainer
              metaJsonLdContainer
              metaSiteVarsContainer
              frontendTemplateContainer
            }
            id
            title: pageTitle
            uri
            section2ContentTitle
            contentDefaultHtmlEducation1
          }
        }
        files: assets(volume:"websiteEducationGeneralTeacherGuidesPdfs" orderBy: "title") {
          ... on Craft_websiteEducationGeneralTeacherGuidesPdfs_Asset {
            key: id
            title
            gradeOptionValue: grade
            gradeOptionLabel: grade(label: true)
            url
          }
        }
      }
    }
  `)

    let itemsPerPage = 10
    let gradeOptions = false
    let files = data.craft.files

    const [gradeFilter, setGradeFilter] = useState(false)
    const [paginatedFiles, setPaginatedFiles] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)

    const getGradeOptions = () => {
        if (gradeOptions) {
            return gradeOptions
        }

        gradeOptions = files
            .filter((e, i) => files.findIndex(a => a["gradeOptionValue"] === e["gradeOptionValue"]) === i)
            .map(option => (
                {
                    title: option.gradeOptionLabel,
                    value: option.gradeOptionValue
                }
            ))
            .sort((a, b) => a.title.localeCompare(b.title))

        // Add all option
        gradeOptions.unshift({
            title: "All Grades",
            value: ""
        })

        return gradeOptions
    }

    const changeGradeFilter = (event) => {
        setGradeFilter(event.target.value)
        setCurrentPage(0)
    }

    const renderFiles = () => {
        if (paginatedFiles) {
            return paginatedFiles.map(file => (
                <li><a href={file.url} target="_blank" rel="noreferrer">{file.title}</a></li>
            ))
        } else {
            return files.slice(0, itemsPerPage).map(file => (
                <li><a href={file.url} target="_blank" rel="noreferrer">{file.title}</a></li>
            ))
        }
    }

    const filterFiles = (filesList) => {
        if (filesList) {
            if (gradeFilter) {
                filesList = filesList.filter(file => { return file.gradeOptionValue === gradeFilter })
            }

            return filesList
        }
    }

    return (
        <InnerLayout seomatic={data.craft.page.seomatic} entryId={data.craft.page.id}>
            <div className="midd education-hallway-heroes-teacher-guides-page">

                <PageTitle title={data.craft.page.title} className="hallway-heroes" />

                <div className="content-page">
                    <div className="container-small">

                        <div className="content-colum-row padding-top-none">
                            <div className="sidebar">
                                <HallwayHeroesNavigation active={data.craft.page.uri} />
                            </div>
                            <div className="content-right">
                                <h1>{data.craft.page.section2ContentTitle}</h1>
                                {parse(data.craft.page.contentDefaultHtmlEducation1)}

                                <label htmlFor="grade-select">Select a Grade:</label>
                                <Select id="grade-select" name="grade-select" changeHandler={changeGradeFilter} options={getGradeOptions()} />

                                <ul className="files">
                                    {renderFiles()}
                                </ul>

                                <ClientPagination items={filterFiles(files)} watchList={[currentPage, gradeFilter]} currentPage={currentPage} itemsPerPage={itemsPerPage} changePageHandler={setCurrentPage} changeItemsHandler={setPaginatedFiles}/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default EducatorsTeacherGuidesPage
